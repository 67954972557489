import '../../Assets/scss/components/init-msg-block.scss';
import { Link } from 'react-router-dom';

const FreeVersionOverlay = () => {

    

    return (
        <>
            <div className='aioa_dashboard-init-msg-block d-flex align-items-center justify-content-center'>
                <div className='upgrade-to-paid-popup-wrapper text-center rounded-4 bg-white shadow-lg p-5 w-50'>
                    <p className='fs-4 fw-bold'>Upgrade to the paid version to view all the Dashboard features.</p>
                    <Link to="/front/manage-plan" className='btn btn-primary btn-lg'>Upgrade Plan</Link>
                </div>
            </div>

        </>
    )
}

export default FreeVersionOverlay;