import { Button, Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../../Assets/scss/components/user-menu-dropdown.scss";
import { useState } from "react";
import UserLogoutActionService from "../../Services/UserAction/LogoutActionService";

const UserDropdownButton = () => {

    // Functions
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Redux Data
    const { user, accessToken } = useSelector((store: StoreProptypes) => store);

    // State
    const [showLogOutConfirmModal, setShowLogOutConfirmModal] = useState<boolean>(false);

    // Popup Modal Function
    const handleLogOutConfirmModalClose = () => {
        setShowLogOutConfirmModal(false);
    }
    const handleLogOutConfirmModalShow = () => {
        setShowLogOutConfirmModal(true);
    }

    // logout
    const handleLogout = () => {
        if (accessToken) {
            UserLogoutActionService(accessToken, dispatch, navigate, t, handleLogOutConfirmModalClose);
        }
    }

    return (
        <>
            {user && (
                <>
                    <Dropdown className="aioa_dashboard-user-dropdown-btn">


                        <OverlayTrigger overlay={<Tooltip>{user.name} - {user.company_name}</Tooltip>}>
                            <Dropdown.Toggle id="user-menu-dropdown">
                                <div className="aioa_dashboard-user-dropdown-btn-wrapper">
                                    <div className="aioa_dashboard-user-dropdown-btn-icon">
                                        {user.name && user.name.charAt(0)}
                                    </div>
                                    <div className="aioa_dashboard-user-dropdown-btn-text">
                                        <span className="aioa_dashboard-user-dropdown-btn-user-name">{user.name}</span>
                                        <span className="aioa_dashboard-user-dropdown-btn-company-name">
                                            {user.company_name}
                                        </span>
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                        </OverlayTrigger>



                        <Dropdown.Menu>
                            <Dropdown.Item as={`div`} className="aioa_dashboard-user-dropdown-btn-text">
                                <span className="aioa_dashboard-user-dropdown-btn-user-name">{user.name}</span>
                                <span className="aioa_dashboard-user-dropdown-btn-company-name">{user.company_name}</span>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item as={Link} to="/front/edit-profile">
                                <i className="material-symbols-outlined" aria-hidden="true">person</i>
                                <span>{t("Profile")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to="/front/settings">
                                <i className="material-symbols-outlined" aria-hidden="true">settings</i>
                                <span>{t('Settings')}</span>
                            </Dropdown.Item>
                            <Dropdown.Item as={`button`} className="logout-btn" onClick={() => handleLogOutConfirmModalShow()}>
                                <i className="material-symbols-outlined" aria-hidden="true">logout</i>
                                <span>{t("Logout")}</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Modal show={showLogOutConfirmModal} onHide={handleLogOutConfirmModalClose}>
                        <Modal.Header>
                            <Modal.Title>{t("Logout")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{t('logout modal msg')}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => handleLogout()} size="lg" className="aioa_dashboard-icon-btn">
                                <div className="aioa_dashboard-btn-icon"><i className="material-symbols-outlined" aria-hidden="true">check</i></div>
                                <div className="aioa_dashboard-btn-text">{t('yes btn label')}</div>
                            </Button>
                            <Button variant="danger" onClick={handleLogOutConfirmModalClose} size="lg" className="aioa_dashboard-icon-btn">
                                <div className="aioa_dashboard-btn-icon"><i className="material-symbols-outlined" aria-hidden="true">close</i></div>
                                <div className="aioa_dashboard-btn-text">{t('no btn label')}</div>
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )}
        </>
    )
}

export default UserDropdownButton;